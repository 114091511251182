import Inputmask from "inputmask";
import sendMail from "./sendMail.js"

export default function sendForm() {

  const validateName = (name) => {
    if (name.length >= 2 && name.length < 50) {
      return name.match(
        /^[а-яА-Я]/
      );
    }
  }

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const validatePhone = (phone) => {
    return phone.match(
      /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/
    );
  }

  const validatedName = () => {
    const name = $('input[name="name"]').val();

    if (validateName(name)) {
      $('input[name="name"]').closest('.contacts__input').removeClass('error').addClass('valid');
    } else {
      $('input[name="name"]').closest('.contacts__input').addClass('error').removeClass('valid');
    }
    if (name == 0) {
      $('input[name="name"]').closest('.contacts__input').removeClass('error').removeClass('valid');
    }
    return false;
  }

  const validatedEmail = () => {
    const email = $('input[name="email"]').val();

    if (validateEmail(email)) {
      $('input[name="email"]').closest('.contacts__input').removeClass('error').addClass('valid');
    } else {
      $('input[name="email"]').closest('.contacts__input').addClass('error').removeClass('valid');
    }
    if (email == 0) {
      $('input[name="email"]').closest('.contacts__input').removeClass('error').removeClass('valid');
    }
    return false;
  }

  const validatedPhone = () => {
    const phone = $('input[name="phone"]').val();

    if (validatePhone(phone) && phone.length > 0) {
      $('input[name="phone"]').closest('.contacts__input').removeClass('error').addClass('valid');
    } else {
      $('input[name="phone"]').closest('.contacts__input').addClass('error').removeClass('valid');
    }
    if (phone == 0) {
      $('input[name="phone"]').closest('.contacts__input').removeClass('error').removeClass('valid');
    }
    return false;
  }

  $('input[name="name"]').on('input', validatedName);
  $('input[name="email"]').on('input', validatedEmail);
  $('input[name="phone"]').on('input', validatedPhone);

  $('.contacts__input input').on('input', function() {
    if ($('input[name="name"]').val().length > 0) {
      $('input[name="name"]').next().addClass('active');
    } else {
      $('input[name="name"]').next().removeClass('active');
    }

    if ($('input[name="phone"]').val().length > 0) {
      $('input[name="phone"]').next().addClass('active');
    } else {
      $('input[name="phone"]').next().removeClass('active');
    }

    if ($('input[name="email"]').val().length > 0) {
      $('input[name="email"]').next().addClass('active');
    } else {
      $('input[name="email"]').next().removeClass('active');
    }
    
    if (
      $('input[name="name"]').closest('.contacts__input').hasClass('valid') &&
      $('input[name="email"]').closest('.contacts__input').hasClass('valid') &&
      $('input[name="phone"]').closest('.contacts__input').hasClass('valid')
    ) {
      $('.contacts__button').removeClass('disabled');
    } else {
      $('.contacts__button').addClass('disabled');
    }
  });

  function sendPopupForm() {
    let form = $('form');
    let timer = 0;
    if (form) {
      form.on('submit', function(e) {
        e.preventDefault();
        if (
          $('input[name="name"]').closest('.contacts__input').hasClass('valid') &&
          $('input[name="email"]').closest('.contacts__input').hasClass('valid') &&
          $('input[name="phone"]').closest('.contacts__input').hasClass('valid')) {
          sendMail(form).then(function() {
            form.get(0).reset();
            $('input').closest('.contacts__input').removeClass('error');
            $('input').closest('.contacts__input').removeClass('valid');
            clear.removeClass('active');
            $('.contacts__button').addClass('disabled');
            $('.contacts__button').text('Ваша заявка отправлена!')
            timer = setTimeout(function() {
              $('.contacts__button').text('Отправить')
              clearTimeout(timer);
            }, 2000);
          });
        } else {
          $('.contacts__button').text('Ошибка отправки!');
        }
      });
    }
  }
  sendPopupForm();

  let clear = $('.contacts__icon');
  clear.on('click', function() {
    $(this).prev().val('');
    $(this).removeClass('active');
    $(this).parent().removeClass('error');
    $('.contacts__button').addClass('disabled');
  });

  function inputMask() {
    let input =  $('input[type="tel"]');
    Array.from(input).forEach(function(element) {
      let mask = new Inputmask('+7 (999) 999-99-99');
      mask.mask(element);
    });
  }
  inputMask();
}
setTimeout(function() {
  sendForm();
}, 100);