import ScrollReveal from "scrollreveal";

export default function scrollInit() {
  const options = {
    distance: "20%",
    origin: "bottom",
    delay: 300,
    opacity: 0,
    mobile: true,
  };
  const options_top = {
    distance: "20%",
    origin: "top",
    opacity: 0,
    mobile: true,
  };
  const options_delay_300 = {
    distance: "20%",
    origin: "bottom",
    opacity: 0,
    delay: 300,
    mobile: true,
  };
  const options_delay_500 = {
    distance: "20%",
    origin: "bottom",
    opacity: 0,
    delay: 500,
    mobile: true,
  };
  const options_delay_700 = {
    distance: "20%",
    origin: "bottom",
    opacity: 0,
    delay: 700,
    mobile: true,
  };
  const options_delay_900 = {
    distance: "20%",
    origin: "bottom",
    opacity: 0,
    delay: 900,
    mobile: true,
  };
  ScrollReveal().reveal(".reveal", options);
  ScrollReveal().reveal(".reveal-top", options_top);
  ScrollReveal().reveal(".reveal-delay-300", options_delay_300);
  ScrollReveal().reveal(".reveal-delay-500", options_delay_500);
  ScrollReveal().reveal(".reveal-delay-700", options_delay_700);
  ScrollReveal().reveal(".reveal-delay-900", options_delay_900);
}